



import { Component, Vue } from 'vue-property-decorator';
import {Route} from 'vue-router';
import StepNavi from '../../../../../components/contents/stepNavi/StepNavi.vue';
import store from '@/store';

Component.registerHooks([
  'beforeRouteEnter',
]);

@Component({
  components: {
    StepNavi,
  },
})
export default class AccountCourseDetailCancelComplete extends Vue {
  private get courseDetail(): any {
    const $parent: any = this.$parent;
    return $parent.courseDetail;
  }

  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  private get title(): any {
    const $parent: any = this.$parent;
    return $parent.title;
  }

  private paramsName: any = '';
  get accountCourseUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_COURSE;
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    store.commit(
      'window/setTitle',
      `キャンセル完了 ${store.getters['window/isTitle']} 申込み講座のキャンセル ` + process.env.VUE_APP_TITLE_SUFFIX,
    );
    next();
  }

}
